<template>
  <!--begin::Navbar-->
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="
              symbol symbol-100px symbol-lg-160px symbol-fixed
              position-relative
            "
          >
            <img src="/media/avatars/incline-avator.png" alt="image" />
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <!--begin:Licensed Distributor Account Info-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-1 fw-bolder me-1"
                  >{{ accountInfo.firstName }} {{ accountInfo.lastName }}
                </a>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-1">
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    mb-1
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com011.svg"
                    />
                  </span>
                  {{ accountInfo.email }}
                </a>
              </div>
              <div>
                <span v-show="!accountInfo.archive" class="badge badge-success"
                  >Active</span
                ><span v-show="accountInfo.archive" class="badge badge-danger"
                  >Deactivated</span
                >
              </div>
              <!--end::Info-->
            </div>
            <!--end:Licensed Distributor Account Info-->

            <!--begin:Survey Results Download-->
            <div class="d-flex my-4">
              <router-link
                :to="{
                  name: 'account-overview',
                  params: {
                    accountid: accountInfo.id,
                    clientid: 0,
                  },
                }"
                class="btn btn-sm btn-primary me-3"
                active-class="active"
              >
                User Settings</router-link
              >
            </div>
            <!--end:Survey Results Download-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-2 fw-bolder">
                      {{ d(accountInfo.accountCreatedLocal) }}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Account Created</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-3 fw-bolder">Associates</div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-5 text-gray-600">
                    {{ licensedDistInfo.numOfEmployees }}
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-2 fw-bolder">Clients</div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">
                    {{ licensedDistInfo.numOfClients }}
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <!--begin::Navs-->
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          "
        >
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="{
                name: 'sa-licensed-distributor-clients',
                params: {
                  accountid: accountid,
                },
              }"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Clients</router-link
            >
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="{
                name: 'sa-licensed-distributor-associates',
                params: {
                  accountid: accountid,
                },
              }"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Associates</router-link
            >
          </li>
          <!--end::Nav item-->
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <!--end::Navbar-->
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "incline-licensed-distributor",
  props: ["accountid"],
  components: {},
  setup(props) {
    const store = useStore();

    const accountInfo = computed(() => {
      return store.getters.getAccountInfo(props.accountid);
    });

    const licensedDistInfo = computed(() => {
      return store.getters.getLicensedDistributorInfo(String(props.accountid));
    });

    const userRole = computed(() => store.getters.currentUserRole);

    const { d } = useI18n();

    return {
      accountInfo,
      licensedDistInfo,
      userRole,
      d,
    };
  },
});
</script>
