
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "incline-licensed-distributor",
  props: ["accountid"],
  components: {},
  setup(props) {
    const store = useStore();

    const accountInfo = computed(() => {
      return store.getters.getAccountInfo(props.accountid);
    });

    const licensedDistInfo = computed(() => {
      return store.getters.getLicensedDistributorInfo(String(props.accountid));
    });

    const userRole = computed(() => store.getters.currentUserRole);

    const { d } = useI18n();

    return {
      accountInfo,
      licensedDistInfo,
      userRole,
      d,
    };
  },
});
